<template>
  <div class="container with-price-box">
    <div>
      <h1>Handgeschnittener Schinken von Iberico-Schweinen, die mit Eicheln gemästet wurden</h1>
      <p>
        Umschläge mit etwa 100 g dünn geschnittenem Rohschinken.
      </p>
      <p>
        Die Schweine wurden mit speziellem Wachstumsfutter aufgezogen und zwei Mal mit Eicheln von unseren Steineichen
        (Quercus ilex) gemästet, während sie frei herumliefen. Die Eichelmast findet während der Montanera-Periode
        statt, die von November bis März dauert. In dieser Zeit gibt es kein zusätzliches Futter. Stattdessen ernähren
        sich die Schweine von den Eicheln, die Fett und andere wichtige Nährstoffe liefern, und fressen Gras und was sie
        sonst noch auf dem Land finden.
      </p>
      <p>
        Durch die süßen Eicheln erhält der Schinken einen unverwechselbaren nussigen Geschmack, der dem Aroma der
        Eicheln selbst ähnelt.
      </p>

      <picture>
        <a href="/img/shop/PlateOfHam-1080.jpg"><img src="@/assets/images/PlateOfHam-250.jpg" /></a>
      </picture>

      <p>
        Nach dem Schlachten wird der Schinken für mehrere Monate in Salz eingelegt und reift dann für 3 Jahre. Während
        dieses Prozesses verliert er deutlich an Gewicht und sein Geschmack wird intensiver.
      </p>
      <p>In diesem Video sehen Sie, wie er geschnitten wird:</p>

      <CookieControlledContent>
        <iframe data-cy="youtubeVideo" class="youtube-video" src="https://www.youtube.com/embed/iEChpgH_4vU"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </CookieControlledContent>

      <p>
        Der Schinken ist vakuumverpackt und muss nicht gekühlt werden, sollte aber an einem kühlen Ort aufbewahrt
        werden.
        Die Menge der Umschläge ist begrenzt. Bitte sehen Sie rechts, was noch verfügbar ist.
      </p>
      <p>
        Vor dem Verzehr öffnen Sie den Beutel und lassen ihn ein wenig lüften - wie einen guten Wein. Die Scheiben sind
        mundgerecht und werden traditionell zu jeder Tageszeit gegessen. Sie werden feststellen, dass der nussige
        Geschmack noch eine Weile im Mund bleibt, was erwünscht und typisch ist.
      </p>
    </div>
    <PriceBox product="605c6e77-e51b-45e1-aa9e-b32549db0152" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'
import CookieControlledContent from '@/components/CookieControlledContent.vue';

export default {
  name: "Shop",
  components: {
    PriceBox,
    CookieControlledContent
  },
}

</script>
